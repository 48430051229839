.apexcharts-legend-series .apexcharts-legend-marker {
    left: -4px !important;
    right: -4px !important;
}

.apexcharts-legend.apx-legend-position-bottom .apexcharts-legend-series,
.apexcharts-legend.apx-legend-position-top .apexcharts-legend-series {
    gap: 8px;
}
.apexcharts-legend-series {
    display: flex;
    gap: 8px;
}
