[data-rmiz] {
    position: relative;
}
[data-rmiz-content] {
    visibility: visible !important;
}
[data-rmiz-ghost] {
    position: absolute;
    pointer-events: none;
}
[data-rmiz-btn-zoom] {
    background-color: rgba(0, 0, 0, 0.7);
    border-radius: 50%;
    border: none;
    box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
    color: #fff;
    height: 40px;
    margin: 0;
    outline-offset: 2px;
    padding: 9px;
    touch-action: manipulation;
    width: 40px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    display: none;
}
[data-rmiz-btn-unzoom] {
    background-color: rgba(0, 0, 0, 0.7);
    border-radius: 50%;
    border: none;
    box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
    color: #fff;
    height: 40px;
    margin: 0;
    outline-offset: 2px;
    padding: 9px;
    touch-action: manipulation;
    width: 40px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}
[data-rmiz-btn-zoom]:not(:focus):not(:active) {
    position: absolute;
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    height: 1px;
    overflow: hidden;
    pointer-events: none;
    white-space: nowrap;
    width: 1px;
}
[data-rmiz-btn-zoom] {
    position: absolute;
    inset: 10px 10px auto auto;
    cursor: auto;
    display: none;
}
[data-rmiz-btn-unzoom] {
    position: absolute;
    inset: 20px 20px auto auto;
    cursor: auto;
    z-index: 1;
    display: none;
}
[data-rmiz-content='found'] img,
[data-rmiz-content='found'] svg,
[data-rmiz-content='found'] [role='img'],
[data-rmiz-content='found'] [data-zoom] {
    cursor: auto;
}
[data-rmiz-modal]::backdrop {
    display: none;
}
[data-rmiz-modal][open] {
    position: fixed;
    width: 100vw;
    width: 100dvw;
    height: 100vh;
    height: 100dvh;
    max-width: none;
    max-height: none;
    margin: 0;
    padding: 0;
    border: 0;
    background: transparent;
    overflow: hidden;
    /* pointer-events: none; */
}
[data-rmiz-modal-overlay] {
    position: absolute;
    inset: 0;
    transition: background-color 0.3s;
}
[data-rmiz-modal-overlay='hidden'] {
    background-color: rgba(255, 255, 255, 0);
}
[data-rmiz-modal-overlay='visible'] {
    background-color: rgba(255, 255, 255, 0);
}
[data-rmiz-modal-content] {
    position: relative;
    width: 100%;
    height: 100%;
}
[data-rmiz-modal-img] {
    position: absolute;
    cursor: auto;
    image-rendering: high-quality;
    transform-origin: top left;
    transition: transform 0.3s;
    border-radius: 3em;
}
@media (prefers-reduced-motion: reduce) {
    [data-rmiz-modal-overlay],
    [data-rmiz-modal-img] {
        transition-duration: 0.01ms !important;
    }
}
